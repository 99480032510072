import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CadastroHorario.css';

// Defina uma interface para a sua estrutura de dados de local
interface Local {
    idLocal: number;
    nomeLocal: string;
    // Adicione outros campos conforme necessário
}

// Defina a estrutura do formulário de horário
interface FormularioHorario {
    descricaoHorario: string;
    horaInicio: string;
    horaFim: string;
    local: {
        idLocal: number;
    };
    quantidadeVagas: number; // Adicionado campo para a quantidade de vagas
}

const CadastroHorario: React.FC = () => {
    const [locais, setLocais] = useState<Local[]>([]);
    const [formData, setFormData] = useState<FormularioHorario>({
        descricaoHorario: '',
        horaInicio: '',
        horaFim: '',
        local: { idLocal: 0 },
        quantidadeVagas: 1, // Valor inicial para quantidade de vagas
    });

    useEffect(() => {
        const fetchLocais = async () => {
            try {
                const response = await axios.get('http://localhost:8080/locais');
                setLocais(response.data);
            } catch (error) {
                console.error('Erro ao buscar locais:', error);
            }
        };

        fetchLocais();
    }, []);

    // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     try {
    //         const response = await axios.post('http://localhost:8080/horarios', formData);
    //         console.log('Horário cadastrado com sucesso:', response.data);
    //         // Resetar o formulário ou redirecionar o usuário
    //     } catch (error) {
    //         console.error('Erro ao cadastrar horário:', error);
    //     }
    // };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            // Criação do horário
            const responseHorario = await axios.post('http://localhost:8080/horarios', formData);
            console.log('Horário cadastrado com sucesso:', responseHorario.data);

            // Criação da vaga associada ao horário
            const vagaData = {
                horario: {
                    id: responseHorario.data.id // ID do horário recém-criado
                },
                quantidadeVagas: formData.quantidadeVagas
            };

            await axios.post('http://localhost:8080/vagas', vagaData);
            console.log('Vaga criada com sucesso para o horário:', vagaData);

            // Resetar o formulário ou redirecionar o usuário
        } catch (error) {
            console.error('Erro ao cadastrar horário e vaga:', error);
        }
    };


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleLocalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, local: { idLocal: parseInt(e.target.value) } });
    };

    return (
        <form onSubmit={handleSubmit} className="form-container">
            <div>
                <label>Descrição do Horário:</label>
                <input name="descricaoHorario" onChange={handleChange} value={formData.descricaoHorario} />
            </div>

            <div>
                <label>Hora de Início (HH:MM):</label>
                <input type="time" name="horaInicio" onChange={handleChange} value={formData.horaInicio} />
            </div>

            <div>
                <label>Hora de Fim (HH:MM):</label>
                <input type="time" name="horaFim" onChange={handleChange} value={formData.horaFim} />
            </div>

            <div>
                <label>ID do Local:</label>
                <select name="idLocal" onChange={handleLocalChange} value={formData.local.idLocal}>
                    <option value="">Selecione um Local</option>
                    {locais.map((local) => (
                        <option key={local.idLocal} value={local.idLocal}>
                            {local.nomeLocal}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Quantidade de Vagas:</label>
                <input type="number" name="quantidadeVagas" min="1" onChange={(e) => setFormData({ ...formData, quantidadeVagas: parseInt(e.target.value) })} value={formData.quantidadeVagas} />
            </div>


            <button type="submit">Cadastrar Horário</button>
        </form>
    );
};

export default CadastroHorario;


