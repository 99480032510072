import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import axios from 'axios';

const CadastroEmpresa: React.FC = () => {
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        try {
            const response = await axios.post('http://localhost:8080/empresas/cadastro', values);
            console.log('Resposta do servidor:', response);
            // Adicione aqui a lógica de tratamento após o envio bem-sucedido
        } catch (error) {
            console.error('Houve um erro ao enviar o formulário:', error);
            // Adicione aqui a lógica de tratamento de erro
        }
    };

    return (
        <Card title="Cadastro de Empresa" style={{ width: 300 }}>
            <Form
                form={form}
                name="cadastroEmpresa"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Nome da Empresa"
                    name="nomeEmpresa"
                    rules={[{ required: true, message: 'Por favor, insira o nome da empresa!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="CNPJ"
                    name="cnpjEmpresa"
                    rules={[{ required: true, message: 'Por favor, insira o CNPJ!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Endereço"
                    name="endereco"
                    rules={[{ required: true, message: 'Por favor, insira o endereço!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Telefone"
                    name="telefone"
                    rules={[{ required: true, message: 'Por favor, insira o telefone!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Por favor, insira o email!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
                        Cadastrar
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default CadastroEmpresa;
