import React from 'react';
import { Link } from 'react-router-dom';
import './HeaderBar.css'; // Certifique-se de ajustar o CSS conforme necessário

const HeaderBar = () => {
    return (
        <div className="header-bar">
            <h1>Norcode</h1>
            <nav>
                <Link to="/" className="link-button">Home</Link>
                <div className="dropdown">
                    <button className="dropbtn">Cadastro</button>
                    <div className="dropdown-content">
                        <Link to="/cadastro-empresa">Empresa</Link>
                        <Link to="/cadastro-locais">Locais</Link>
                        <Link to="/cadastro-horarios">Horários</Link>
                        <Link to="/cadastro-plantonista">Plantonista</Link>
                    </div>
                </div>
                <div className="dropdown">
                    <button className="dropbtn">Visualizar</button>
                    <div className="dropdown-content">
                        <Link to="/empresas">Empresas</Link>
                        <Link to="/locais">Locais</Link>
                        <Link to="/horarios">Horários</Link>
                        <Link to="/plantonistas">Plantonistas</Link>
                    </div>
                </div>
                <Link to="/escala" className="link-button">Escala</Link>
            </nav>
        </div>
    );
};

export default HeaderBar;
