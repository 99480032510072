import React from 'react';

const HomePage: React.FC = () => {
    return (
        <div>
            <h1>Bem-vindo à Aplicação</h1>
            <p>Esta é a página inicial da sua aplicação.</p>
            {/* Aqui você pode adicionar mais conteúdo conforme necessário */}
        </div>
    );
};

export default HomePage;
