import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CadastroLocaisPage.css';

// Defina uma interface para a sua estrutura de dados de empresa
interface Empresa {
    cnpjEmpresa: string;
    nomeEmpresa: string;
    // Adicione outros campos conforme necessário
}

// Defina a estrutura do formulário
interface FormularioLocal {
    nomeLocal: string;
    endereco: string;
    telefone: string;
    email: string;
    tipoLocal: string;
    empresa: {
        cnpjEmpresa: string;
    };
}

const CadastroLocaisPage: React.FC = () => {
    const [empresas, setEmpresas] = useState<Empresa[]>([]);
    const [formData, setFormData] = useState<FormularioLocal>({
        nomeLocal: '',
        endereco: '',
        telefone: '',
        email: '',
        tipoLocal: '',
        empresa: { cnpjEmpresa: '' }
    });

    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const response = await axios.get('http://localhost:8080/empresas');
                setEmpresas(response.data);
            } catch (error) {
                console.error('Erro ao buscar empresas:', error);
            }
        };

        fetchEmpresas();
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8080/locais', formData);
            console.log('Local cadastrado com sucesso:', response.data);
            // Resetar o formulário ou redirecionar o usuário
        } catch (error) {
            console.error('Erro ao cadastrar local:', error);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleEmpresaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, empresa: { cnpjEmpresa: e.target.value } });
    };

    return (
        <form onSubmit={handleSubmit} className="form-container">
            <div>
                <label>Nome do Local:</label>
                <input name="nomeLocal" onChange={handleChange} value={formData.nomeLocal} />
            </div>

            <div>
                <label>Endereço:</label>
                <input name="endereco" onChange={handleChange} value={formData.endereco} />
            </div>

            <div>
                <label>Telefone:</label>
                <input name="telefone" onChange={handleChange} value={formData.telefone} />
            </div>

            <div>
                <label>Email:</label>
                <input name="email" onChange={handleChange} value={formData.email} />
            </div>

            <div>
                <label>Tipo do Local:</label>
                <input name="tipoLocal" onChange={handleChange} value={formData.tipoLocal} />
            </div>

            <div>
                <label>CNPJ da Empresa:</label>
                <select name="cnpjEmpresa" onChange={handleEmpresaChange} value={formData.empresa.cnpjEmpresa}>
                    <option value="">Selecione uma Empresa</option>
                    {empresas.map((empresa) => (
                        <option key={empresa.cnpjEmpresa} value={empresa.cnpjEmpresa}>
                            {empresa.nomeEmpresa}
                        </option>
                    ))}
                </select>
            </div>

            <button type="submit">Cadastrar Local</button>
        </form>
    );
};

export default CadastroLocaisPage;