import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CadastroEmpresa from '../pages/cadastroEmpresa/CadastroEmpresa';
import HomePage from '../pages/home/HomePage';
import EmpresasPage from "../pages/Empresas/EmpresasPage";
import CadastroLocaisPage from "../pages/Locais/CadastroLocaisPage";
import LocaisPage from "../pages/Locais/LocaisPage";
import CadastroHorario from "../pages/Horarios/CadastroHorario";
import HorariosPage from "../pages/Horarios/HorariosPage";
import HeaderBar from "../components/HeaderBar";
import CadastroPlantonistaPage from "../pages/Plantonista/CadastroPlantonistaPage";
import PlantonistaPage from "../pages/Plantonista/PlantonistaPage";
import EscalaPage from "../pages/Escala/EscalaPage";

const AppRoutes: React.FC = () => {
    return (
        <>
            <HeaderBar />
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/cadastro-empresa" element={<CadastroEmpresa />} />
            <Route path="/empresas" element={<EmpresasPage />} />
            <Route path="/cadastro-locais" element={<CadastroLocaisPage />} />
            <Route path="/locais" element={<LocaisPage />} />
            <Route path="/cadastro-horarios" element={<CadastroHorario />} />
            <Route path="/horarios" element={<HorariosPage />}/>
            <Route path="/cadastro-plantonista" element={<CadastroPlantonistaPage />}/>
            <Route path="/plantonistas" element={<PlantonistaPage />}/>
            <Route path="/escala" element={<EscalaPage />}/>
            {/* Adicione mais rotas conforme necessário */}
        </Routes>
        </>
    );
};

export default AppRoutes;
