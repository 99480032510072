import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import './EscalaPage.css';


const EscalaPage: React.FC = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <h1>Escala Page</h1>
            <p>Em construção...</p>
        </div>
    );
};


export default EscalaPage;
