import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LocaisPage.css';


// Defina uma interface para a estrutura de dados do local
interface Local {
    idLocal: number;
    nomeLocal: string;
    endereco: string;
    telefone: string;
    email: string;
    tipoLocal: string;
    dataCadastro: Date;
    cnpjEmpresa: string; // Agora diretamente no objeto Local
}

const LocaisPage: React.FC = () => {
    const [locais, setLocais] = useState<Local[]>([]);

    useEffect(() => {
        const fetchLocais = async () => {
            try {
                const response = await axios.get('http://localhost:8080/locais');
                setLocais(response.data);
            } catch (error) {
                console.error('Erro ao buscar locais:', error);
            }
        };

        fetchLocais();
    }, []);

    return (
        <div>
            <h1>Locais</h1>
            <div className="locais-container">
            {locais.map((local, index) => (
                <div key={index} className="local">
                    <h2>{local.nomeLocal}</h2>
                    <p>Endereço: {local.endereco}</p>
                    <p>Telefone: {local.telefone}</p>
                    <p>Email: {local.email}</p>
                    <p>Tipo: {local.tipoLocal}</p>
                    <p>CNPJ da Empresa: {local.cnpjEmpresa}</p>
                </div>
            ))}
        </div>
        </div>
    );
};

export default LocaisPage;
