import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './HorariosPage.css';

// Interfaces
interface Local {
    idLocal: number;
    nomeLocal: string;
    // Outros campos conforme necessário
}

interface Vaga {
    id: number;
    idHorario: number;
    quantidadeVagas: number;
}

interface Horario {
    id: number;
    descricaoHorario: string;
    horaInicio: string;
    horaFim: string;
    local: Local;
    vaga?: Vaga; // Campo opcional
}

// Componente HorariosPage
const HorariosPage: React.FC = () => {
    // Estados
    const [locais, setLocais] = useState<Local[]>([]);
    const [selectedLocal, setSelectedLocal] = useState<string>('');
    const [horarios, setHorarios] = useState<Horario[]>([]);

    // Efeito para buscar locais
    useEffect(() => {
        const fetchLocais = async () => {
            try {
                const response = await axios.get('http://localhost:8080/locais');
                setLocais(response.data);
            } catch (error) {
                console.error('Erro ao buscar locais:', error);
            }
        };

        fetchLocais();
    }, []);

    // Função para buscar vagas e associá-las aos horários
    const fetchVagasEAssociarAosHorarios = async (horarios: Horario[]) => {
        try {
            const promises = horarios.map(async (horario) => {
                try {
                    const response = await axios.get(`http://localhost:8080/vagas/${horario.id}`);
                    return {...horario, vaga: response.data};
                } catch {
                    return horario; // Retorna o horário mesmo se não encontrar vaga
                }
            });
            return Promise.all(promises);
        } catch (error) {
            console.error('Erro ao buscar vagas:', error);
            return [];
        }
    };

    // Função para buscar horários
    const fetchHorarios = async () => {
        if (!selectedLocal) return;
        try {
            const response = await axios.get(`http://localhost:8080/horarios/local/${selectedLocal}`);
            const horariosComVagas = await fetchVagasEAssociarAosHorarios(response.data);
            setHorarios(horariosComVagas);
        } catch (error) {
            console.error('Erro ao buscar horários:', error);
        }
    };

    // Renderização do componente
    return (
        <div className="horarios-container">
            <div className="horarios-header">
                <h2>Horários por Local</h2>
            </div>
            <div>
                <select className="horarios-select" value={selectedLocal}
                        onChange={(e) => setSelectedLocal(e.target.value)}>
                    <option value="">Selecione um Local</option>
                    {locais.map((local) => (
                        <option key={local.idLocal} value={local.idLocal.toString()}>
                            {local.nomeLocal}
                        </option>
                    ))}
                </select>
                <button onClick={fetchHorarios}>Mostrar Horários</button>
            </div>
            <div>
                {horarios.map((horario) => (
                    <div key={horario.id} className="horario-card">
                        <p className="horario-info"><strong>Descrição:</strong> <span>{horario.descricaoHorario}</span></p>
                        <p className="horario-info"><strong>Início:</strong> <span>{horario.horaInicio}</span></p>
                        <p className="horario-info"><strong>Fim:</strong> <span>{horario.horaFim}</span></p>
                        {horario.vaga && (
                            <p className="vaga-info">
                                <strong>Vagas Disponíveis:</strong> <span>{horario.vaga.quantidadeVagas}</span>
                            </p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HorariosPage;
