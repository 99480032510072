import React, { useState } from 'react';
import axios from 'axios';
import './CadastroPlantonistaPage.css';

const CadastroPlantonistaPage: React.FC = () => {
    const [plantonista, setPlantonista] = useState({
        nome: '',
        telefone: '',
        cpf: '',
        contato: ''
    });

    const formatarCPF = (cpf: string) => {
        return cpf
            .replace(/\D/g, '') // Remove tudo o que não é dígito
            .replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto depois do terceiro dígito
            .replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto depois do sexto dígito
            .replace(/(\d{3})(\d{1,2})/, '$1-$2') // Coloca um traço antes dos últimos dois dígitos
            .replace(/(-\d{2})\d+?$/, '$1'); // Permite apenas dois dígitos após o traço
    };

    const formatarTelefone = (telefone: string) => {
        return telefone
            .replace(/\D/g, '') // Remove tudo o que não é dígito
            .replace(/(\d{2})(\d)/, '($1)$2') // Coloca parênteses em torno dos dois primeiros dígitos
            .replace(/(\d{4,5})(\d{4})/, '$1-$2') // Coloca um traço entre o quarto e quinto dígitos
            .replace(/(-\d{4})\d+?$/, '$1'); // Permite apenas quatro dígitos após o traço
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let valorFormatado = value;

        if (name === 'cpf') {
            valorFormatado = formatarCPF(value);
        } else if (name === 'telefone') {
            valorFormatado = formatarTelefone(value);
        }

        setPlantonista({ ...plantonista, [name]: valorFormatado });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await axios.post('http://localhost:8080/plantonistas/criar', plantonista);
            // Tratamento adicional após o sucesso do cadastro
        } catch (error) {
            console.error('Erro ao cadastrar plantonista:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form-container">
            <div>
                <label>Nome:</label>
                <input name="nome" onChange={handleChange} value={plantonista.nome} />
            </div>

            <div>
                <label>CPF:</label>
                <input type="text" name="cpf" value={plantonista.cpf} onChange={handleChange} />
            </div>
            <div>
                <label>Telefone:</label>
                <input type="text" name="telefone" value={plantonista.telefone} onChange={handleChange} />
            </div>

            <div>
                <label>Contato (E-mail):</label>
                <input name="contato" onChange={handleChange} value={plantonista.contato} />
            </div>

            <button type="submit">Cadastrar Plantonista</button>
        </form>
    );
};

export default CadastroPlantonistaPage;