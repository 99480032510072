import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PlantonistaPage.css';

// Interface para o plantonista
interface Plantonista {
    id: number;
    nome: string;
    telefone?: string;
    cpf?: string;
    contato: string;
}

const PlantonistaPage: React.FC = () => {
    const [plantonistas, setPlantonistas] = useState<Plantonista[]>([]);
    const [editandoPlantonistaId, setEditandoPlantonistaId] = useState<number | null>(null);
    const [dadosEdicao, setDadosEdicao] = useState<Plantonista | null>(null);

    useEffect(() => {
        fetchPlantonistas();
    }, []);

    const fetchPlantonistas = async () => {
        try {
            const response = await axios.get('http://localhost:8080/plantonistas');
            setPlantonistas(response.data);
        } catch (error) {
            console.error('Erro ao buscar plantonistas:', error);
        }
    };

    const iniciarEdicao = (plantonista: Plantonista) => {
        setEditandoPlantonistaId(plantonista.id);
        setDadosEdicao({ ...plantonista });
    };

    const cancelarEdicao = () => {
        setEditandoPlantonistaId(null);
        setDadosEdicao(null);
    };

    const salvarEdicao = async () => {
        if (!dadosEdicao) return;
        try {
            await axios.put(`http://localhost:8080/plantonistas/${dadosEdicao.id}`, dadosEdicao);
            fetchPlantonistas();
            cancelarEdicao();
        } catch (error) {
            console.error('Erro ao salvar plantonista:', error);
        }
    };

    const confirmarExclusao = async (id: number) => {
        if (window.confirm('Deseja remover o plantonista?')) {
            try {
                await axios.delete(`http://localhost:8080/plantonistas/${id}`);
                fetchPlantonistas();
            } catch (error) {
                console.error('Erro ao excluir plantonista:', error);
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDadosEdicao({ ...dadosEdicao as Plantonista, [name]: value });
    };

    return (
        <div className="plantonistas-container">
            <h1>Plantonistas</h1>
            <table>
                <thead>
                <tr>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th>CPF</th>
                    <th>Contato</th>
                    <th>Ações</th>
                </tr>
                </thead>
                <tbody>
                {plantonistas.map((plantonista) => (
                    <tr key={plantonista.id}>
                        {editandoPlantonistaId === plantonista.id ? (
                            <>
                                <td><input type="text" name="nome" value={dadosEdicao?.nome} onChange={handleChange} /></td>
                                <td><input type="text" name="telefone" value={dadosEdicao?.telefone} onChange={handleChange} /></td>
                                <td><input type="text" name="cpf" value={dadosEdicao?.cpf} onChange={handleChange} /></td>
                                <td><input type="text" name="contato" value={dadosEdicao?.contato} onChange={handleChange} /></td>
                                <td>
                                    <button onClick={salvarEdicao} className="icon-button"><i className="fas fa-save"></i></button>
                                    <button onClick={cancelarEdicao} className="icon-button"><i className="fas fa-times"></i></button>
                                </td>
                            </>
                        ) : (
                            <>
                                <td>{plantonista.nome}</td>
                                <td>{plantonista.telefone}</td>
                                <td>{plantonista.cpf}</td>
                                <td>{plantonista.contato}</td>
                                <td>
                                    <button onClick={() => iniciarEdicao(plantonista)} className="icon-button"><i className="fas fa-pencil-alt"></i></button>
                                    <button onClick={() => confirmarExclusao(plantonista.id)} className="icon-button"><i className="fas fa-trash"></i></button>
                                </td>
                            </>
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default PlantonistaPage;
