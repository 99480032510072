import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EmpresasPage.css';


// Defina uma interface para a sua estrutura de dados de empresa
interface Empresa {
    cnpjEmpresa: string;
    nomeEmpresa: string;
    endereco: string;
    telefone: string;
    email: string;
    dataCadastro?: string; // Use "?" se o campo for opcional
}

const EmpresasPage: React.FC = () => {
    const [empresas, setEmpresas] = useState<Empresa[]>([]); // Deixe claro que é um array de Empresa

    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const response = await axios.get('http://localhost:8080/empresas');
                setEmpresas(response.data);
            } catch (error) {
                console.error('Erro ao buscar empresas:', error);
                // Tratar o erro conforme necessário
            }
        };

        fetchEmpresas();
    }, []);

    return (
        <div className="empresas-container">
            {empresas.map((empresa, index) => (
                <div key={index} className="empresa">
                    <h2>{empresa.nomeEmpresa}</h2>
                    <p>CNPJ: {empresa.cnpjEmpresa}</p>
                    <p>Endereço: {empresa.endereco}</p>
                    <p>Telefone: {empresa.telefone}</p>
                    <p>Email: {empresa.email}</p>
                    <p>Data de Cadastro: {empresa.dataCadastro}</p>
                </div>
            ))}
        </div>
    );
};

export default EmpresasPage;
